import React, { useEffect, useState } from 'react'
import GridContainer from 'components/creative-tim/Grid/GridContainer.js'
import GridItem from 'components/creative-tim/Grid/GridItem.js'
import {
  cardTitle,
  cardLink,
  cardSubtitle,
} from 'assets/jss/material-kit-react.js'
import {
  makeStyles,
  Tooltip,
  Chip,
  Theme,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import Card from 'components/creative-tim/Card/Card.js'
import CardBody from 'components/creative-tim/Card/CardBody.js'
import CardHeader from 'components/creative-tim/Card/CardHeader.js'
import CardFooter from 'components/creative-tim/Card/CardFooter.js'
import { currencyFormatter } from 'libs/formatters'
import { Categories, eBayFeeModel, PaymentFees } from './Models'
import InfoSharpIcon from '@material-ui/icons/InfoSharp'

const styles = (theme: Theme) => ({
  cardTitle,
  cardLink,
  cardSubtitle,
  textCenter: {
    textAlign: 'center' as const,
  },
  footerLeftAlign: {
    textAlign: 'left' as const,
    display: 'block',
  },
  cardSubtitle2: {
    marginBottom: '0',
    marginTop: '0',
  },
  categoryPaymentDiv: {
    display: 'flex' as const,
    justifyContent: 'center' as const,
    flexWrap: 'wrap' as const,
    '& > *': {
      margin: theme.spacing(0.1),
    },
    marginBottom: theme.spacing(1),
  },
})

const useStyles = makeStyles(styles)

export type CalculationData = Omit<eBayFeeModel, 'ebayFees' | 'grossProfit'> & {
  ebayFees?: number
  grossProfit?: number
  ebayCategory?: Categories
  paymentFeesProvider?: PaymentFees
  vatReg?: boolean
}

type CalculationResults = {
  results: CalculationData
  loading?: boolean
}

const Result: React.FC<CalculationResults> = props => {
  const classes = useStyles()
  const formatter = currencyFormatter
  const [hasCategoryTooltip, setHasCategoryTooltip] = useState(false)

  useEffect(() => {
    setHasCategoryTooltip(
      !!(
        props.results.ebayCategory?.MaxFee ||
        props.results.ebayCategory?.MinFee ||
        (props.results.ebayFeeBreakdown &&
          props.results.ebayFeeBreakdown.filter(x => x.Amount).length > 1)
      )
    )
  }, [props.results.ebayCategory, props.results.ebayFeeBreakdown])

  const categoryTooltip = () => {
    if (hasCategoryTooltip) {
      return (
        <List data-testid="category-tooltip" dense>
          {props.results.ebayFeeBreakdown?.map(f => (
            <ListItem key={`breakdown-${f.Percentage}`}>
              <ListItemText>
                {f.Percentage}% - {currencyFormatter.format(f.Amount)}
              </ListItemText>
            </ListItem>
          ))}
          {props.results.ebayCategory?.MinFee && (
            <ListItem key="categoryMaxFee">
              <ListItemText>
                Min: {formatter.format(props.results.ebayCategory.MinFee)}, this
                will apply for sale up to{' '}
                {formatter.format(
                  props.results.ebayCategory.MinFee /
                    (props.results.ebayCategory.FinalFee[0].Fee / 100)
                )}
              </ListItemText>
            </ListItem>
          )}
          {props.results.ebayCategory?.MaxFee && (
            <ListItem key="categoryMinFee">
              <ListItemText>
                Max: {formatter.format(props.results.ebayCategory.MaxFee.Fee)},
                this will apply for any sale over{' '}
                {formatter.format(
                  props.results.ebayCategory.MaxFee.Fee /
                    (props.results.ebayCategory.FinalFee[0].Fee / 100)
                )}
              </ListItemText>
            </ListItem>
          )}
        </List>
      )
    }
  }

  return (
    <GridContainer data-testid="results">
      {props.results && props.results.ebayFees !== undefined ? (
        <GridItem xs>
          <Card className={classes.textCenter}>
            <CardHeader color="info">eBay Fees</CardHeader>
            <CardBody>
              {props.results.ebayFeesBeforeDiscount && (
                <del>
                  <h3>
                    {formatter.format(props.results.ebayFeesBeforeDiscount)}
                  </h3>
                </del>
              )}
              <div className={classes.categoryPaymentDiv}>
                <h3 data-testid="eBayFees">
                  {formatter.format(props.results.ebayFees)}
                </h3>
                {props.results.ebayInsertionFees && (
                  <Tooltip title="Insertion Fee" arrow enterTouchDelay={300}>
                    <Chip
                      data-testid="insertion-chip"
                      variant="outlined"
                      color="secondary"
                      size="small"
                      label={`+ ${formatter.format(
                        props.results.ebayInsertionFees
                      )}`}
                      icon={<InfoSharpIcon />}
                      clickable={true}
                    />
                  </Tooltip>
                )}
              </div>
              {props.results.ebayCategory && (
                <div className={classes.categoryPaymentDiv}>
                  {props.results.ebayFeeBreakdown &&
                    props.results.ebayFeeBreakdown
                      .filter(x => x.Amount > 0)
                      .map(f => (
                        <Chip
                          key={f.Percentage}
                          color="primary"
                          size="small"
                          label={`${f.Percentage}%`}
                        />
                      ))}
                  {!props.results.ebayFeeBreakdown && (
                    <Chip
                      color="primary"
                      size="small"
                      label={`${props.results.ebayCategory.FinalFee[0].Fee}%`}
                    />
                  )}
                  <Tooltip
                    arrow
                    interactive
                    title={categoryTooltip() || ''}
                    enterTouchDelay={300}
                  >
                    <Chip
                      data-testid="category-chip"
                      variant="outlined"
                      color="primary"
                      size="small"
                      label={props.results.ebayCategory.Name}
                      icon={hasCategoryTooltip ? <InfoSharpIcon /> : undefined}
                      clickable={true}
                    />
                  </Tooltip>
                </div>
              )}
            </CardBody>
            <CardFooter className={classes.footerLeftAlign}>
              {props.results.ebayFeesBeforeCap &&
                props.results.ebayFees < props.results.ebayFeesBeforeCap && (
                  <h6 className={classes.cardSubtitle2}>
                    The fee was capped, it would have been{' '}
                    {formatter.format(props.results.ebayFeesBeforeCap)}{' '}
                    otherwise
                  </h6>
                )}
              {props.results.ebayFeesBeforeCap &&
                props.results.ebayFees > props.results.ebayFeesBeforeCap && (
                  <h6 className={classes.cardSubtitle2}>
                    The fee was uplifted, it would have been{' '}
                    {formatter.format(props.results.ebayFeesBeforeCap)}{' '}
                    otherwise
                  </h6>
                )}
              {props.results.ebayInsertionFees && (
                <h6 className={classes.cardSubtitle2}>
                  Note Total eBay Fees including Insertion would be{' '}
                  {formatter.format(
                    props.results.ebayInsertionFees + props.results.ebayFees
                  )}{' '}
                </h6>
              )}
              {props.results.vatReg && props.results.ebayFeesVat && (
                <h6 className={classes.cardSubtitle2}>
                  Note that{' '}
                  {formatter.format(
                    props.results.ebayFeesVat +
                      (props.results.ebayInsertionFeesVat || 0)
                  )}{' '}
                  was not added as you would claim this back from HMRC, total
                  inc Vat would be{' '}
                  {formatter.format(
                    props.results.ebayFees +
                      props.results.ebayFeesVat +
                      (props.results.ebayInsertionFees || 0) +
                      (props.results.ebayInsertionFeesVat || 0)
                  )}
                </h6>
              )}
              {!props.results.vatReg && props.results.ebayFeesVat && (
                <h6 className={classes.cardSubtitle2}>
                  Note that{' '}
                  {formatter.format(
                    props.results.ebayFeesVat +
                      (props.results.ebayInsertionFeesVat || 0)
                  )}{' '}
                  was added due to VAT
                </h6>
              )}
            </CardFooter>
          </Card>
        </GridItem>
      ) : null}
      {props.results && props.results.paymentFees !== undefined ? (
        <GridItem xs>
          <Card className={classes.textCenter}>
            <CardHeader color="info">Payment Fees</CardHeader>
            <CardBody>
              <h3 data-testid="paymentFees">
                {formatter.format(props.results.paymentFees)}
              </h3>
              {props.results.paymentFeesProvider && (
                <div className={classes.categoryPaymentDiv}>
                  <Chip
                    color="primary"
                    size="small"
                    label={`${
                      props.results.paymentFeesProvider.Fee.Percentage
                    }% + ${formatter.format(
                      props.results.paymentFeesProvider.Fee.FlatFee || 0
                    )}`}
                  />
                  <Chip
                    variant="outlined"
                    color="primary"
                    size="small"
                    label={props.results.paymentFeesProvider.Name}
                  />
                </div>
              )}
            </CardBody>
          </Card>
        </GridItem>
      ) : null}
      {props.results && props.results.grossProfit !== undefined ? (
        <GridItem xs={12}>
          <Card className={classes.textCenter}>
            <CardHeader color="warning">Gross Profit</CardHeader>
            <CardBody>
              <h2 data-testid="grossProfit">
                {formatter.format(props.results.grossProfit)}
              </h2>
            </CardBody>
            {props.results.vatDue && (
              <CardFooter>
                <h6 className={classes.cardSubtitle}>
                  Note that {formatter.format(props.results.vatDue)} was
                  deducted as this is owed to HMRC from the total sale including
                  shipping charge
                </h6>
              </CardFooter>
            )}
          </Card>
        </GridItem>
      ) : null}
      {props.results && props.results.netProfit !== undefined ? (
        <GridItem xs={12}>
          <Card className={classes.textCenter}>
            <CardHeader
              color={props.results.netProfit > 0 ? 'success' : 'danger'}
            >
              Net Profit
            </CardHeader>
            <CardBody>
              <h1 data-testid="netProfit">
                {formatter.format(props.results.netProfit)}
              </h1>
            </CardBody>
          </Card>
        </GridItem>
      ) : null}
    </GridContainer>
  )
}

export default Result
