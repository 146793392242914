/* istanbul ignore file */
import * as Sentry from '@sentry/react'
import jwtSign from 'hooks/useJwtSign'
import { getItemWithExpiry, setItemWithExpiry } from './sessionStorageWrapper'
import fetchRetry from 'fetch-retry'
import { clearFetchSessionState } from './sessionStorageUtility'

const isServer = typeof window === 'undefined'

const newFetch = isServer
  ? global.fetch
  : fetchRetry(global.fetch, {
      retryOn: (attempt: number, error: Error, response: Response) => {
        if (error && error.name === 'AbortError') {
          return false
        }

        if (attempt < 3) {
          // retry on any network error, or 4xx or 5xx status codes
          if (error !== null || response.status >= 400) {
            const headerObj: Record<string, unknown> = {}
            response.headers.forEach((value, key) => {
              headerObj[key] = value
            })

            //token to be removed in the future possibly
            //found that token seems to be valid, but reports it not, so try again if that the case
            Sentry.setExtra('token', getItemWithExpiry<string>('token'))
            Sentry.setExtra('header', headerObj)
            Sentry.setExtra('error', error)
            Sentry.captureMessage(
              `Response Status: ${response.status} retrying, attempt number ${
                attempt + 1
              }`
            )
            return true
          }
        } else {
          clearFetchSessionState()
        }
      },
    })

const updateInit = (init?: RequestInit): RequestInit => {
  const update: RequestInit = { ...init }
  update.headers = {
    Authorization: 'Bearer ' + getAuthenticationToken({}),
  }
  return update
}

const getAuthenticationToken = (payload: Record<string, unknown>): string => {
  let token = getItemWithExpiry<string>('token')

  if (token) return token

  token = jwtSign(payload)
  setItemWithExpiry('token', token, 2, true)
  return token
}

export default function fetcher(
  input: RequestInfo,
  init?: RequestInit
): Promise<Response> {
  return newFetch(input, updateInit(init)).then(res => {
    if (!res.ok) throw Error(`Failed Response as got ${res.status}`)
    return res
  })
}
