import * as Sentry from '@sentry/react'
import { consentGiven } from './cookieConsentUtility'
// Prevent build error "window is undefined" but keep working
const isServer = typeof window === 'undefined'

export const getItem = <TValue>(key: string, initialValue: TValue): TValue => {
  if (isServer || !consentGiven()) {
    return initialValue
  }

  try {
    const item = window.sessionStorage.getItem(key)
    return item ? JSON.parse(item) : initialValue
  } catch (error) {
    Sentry.captureException(error)
    return initialValue
  }
}

export type ItemWithExpiry<TPayload> = {
  item: TPayload
  expiry: Date
}

export const getItemWithExpiry = <TValue>(key: string): TValue | undefined => {
  const item = getItem<ItemWithExpiry<TValue> | undefined>(key, undefined)

  return item
    ? new Date(item.expiry) < new Date()
      ? undefined
      : item.item
    : undefined
}

export const setItemWithExpiry = <TValue>(
  key: string,
  value: TValue,
  timeInMins = 2,
  necessary = false
): void => {
  const item: ItemWithExpiry<TValue> = {
    item: value,
    expiry: new Date(new Date().getTime() + 60000 * timeInMins),
  }
  setItem(key, item, necessary)
}

export const setItem = <TValue>(
  key: string,
  value: TValue,
  necessary = false
): void => {
  try {
    if (!isServer && (consentGiven() || necessary))
      window.sessionStorage.setItem(key, JSON.stringify(value))
  } catch (error) {
    Sentry.captureException(error)
  }
}
