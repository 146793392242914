/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { container } from 'assets/jss/material-kit-react.js'
import { Theme } from '@material-ui/core'

const calculationPageStyle = (theme: Theme) => ({
  container: {
    zIndex: 12,
    marginTop: 15,
    ...container,
  },
  main: {
    background: theme.palette.background.paper,
    position: 'relative' as const,
    zIndex: 3,
  },
  mainRaised: {
    margin: '-40px 30px 0px',
    borderRadius: '6px',
    boxShadow:
      '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
  },
})

export default calculationPageStyle
