import React, { FC } from 'react'
import Layout from 'layout'
import { GatsbySeo, JsonLd } from 'gatsby-plugin-next-seo'
import Calculations from 'components/calculations'
import { WebApplication } from 'schema-dts'

const IndexPage: FC = () => {
  const title = 'eBay and PayPal Fee Calculator | eBay Fees'
  return (
    <Layout>
      <GatsbySeo title={title} openGraph={{ title: title }} />
      <JsonLd<WebApplication>
        json={{
          '@type': 'WebApplication',
          '@context': 'https://schema.org',
          audience: 'eBay users',
          browserRequirements: 'requires Javascript',
        }}
      />
      <Calculations />
    </Layout>
  )
}

export default IndexPage
