/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* istanbul ignore file */
import { currencyFormatter } from 'libs/formatters'
import { DateTime } from 'luxon'
import * as z from 'zod'

export const categorySchema = z.object({
  Name: z.string().nonempty(),
  FinalFee: z.array(
    z.object({
      Fee: z.number(),
      From: z.number().optional(),
      To: z.number().optional(),
      FlatFee: z.number().optional(),
    })
  ),
  InsertionFee: z.number().optional(),
  MaxFee: z
    .object({
      Fee: z.number(),
      OnlyShopSubscribers: z.boolean(),
    })
    .optional(),
  MinFee: z.number().optional(),
  Parent: z.string().optional(),
})

export type Categories = z.infer<typeof categorySchema>

export const vatRateSchema = z.object({
  Name: z.string(),
  Rate: z.number(),
})

export type VatRates = z.infer<typeof vatRateSchema>

const feeBreakdown = z.object({
  Percentage: z.number(),
  Amount: z.number(),
})

export const paymentSchema = z.object({
  Fee: z.object({
    Percentage: z.number(),
    FlatFee: z.number().optional(),
  }),
  Name: z.string().nonempty(),
})

export type PaymentFees = z.infer<typeof paymentSchema>

export const managedSchema = z
  .object({
    managed: z.boolean(),
    payment: paymentSchema.optional().nullable(),
  })
  .refine(val => val.managed || val.payment)

export type ManagedModel = z.infer<typeof managedSchema>

export type EbayFeeBreakdown = z.infer<typeof feeBreakdown>

export const lastUpdatedSchema = z
  .object({
    date: z.string(),
  })
  .refine(input => DateTime.fromISO(input.date).isValid)

export type LastUpdatedApiModel = z.infer<typeof lastUpdatedSchema>

export const eBaySchema = z.object({
  ebayFees: z.number(),
  ebayFeeBreakdown: z.array(feeBreakdown).optional(),
  grossProfit: z.number(),
  ebayInsertionFees: z.number().optional(),
  ebayFeesBeforeCap: z.number().optional(),
  ebayFeesVat: z.number().optional(),
  ebayInsertionFeesVat: z.number().optional(),
  ebayFeesBeforeDiscount: z.number().optional(),
  paymentFees: z.number().optional(),
  vatDue: z.number().optional(),
  netProfit: z.number().optional(),
})
export type eBayFeeModel = z.infer<typeof eBaySchema>

export enum GTagEventCategory {
  Calculations = 'Calculations',
}

export enum GTagEventName {
  Private = 'Private',
  Business = 'Business',
}

export function categoryTag(
  _strings: TemplateStringsArray,
  categorySelected: Categories,
  shopSubscriber: boolean
) {
  const finalFeeArray: string[] = []

  const result = categorySchema.safeParse(categorySelected)
  if (!result.success) {
    return ''
  }

  categorySelected.FinalFee.forEach(f => {
    const fromToArray: string[] = []
    fromToArray.push(
      (f.From && 'From: ' + currencyFormatter.format(f.From)) || ''
    )
    fromToArray.push((f.To && 'To: ' + currencyFormatter.format(f.To)) || '')
    finalFeeArray.push(
      `${f.Fee}% ${
        (f.FlatFee && '+ ' + currencyFormatter.format(f.FlatFee)) || ''
      } ${
        f.From || f.To ? `(${fromToArray.filter(t => t !== '').join(' ')})` : ''
      }`
    )
  })
  return `Fee: ${finalFeeArray.join(', ')} ${
    categorySelected.MinFee
      ? `Min: ${currencyFormatter.format(categorySelected.MinFee)}`
      : ''
  } ${
    categorySelected.MaxFee &&
    (!categorySelected.MaxFee.OnlyShopSubscribers ||
      categorySelected.MaxFee.OnlyShopSubscribers === shopSubscriber)
      ? `Max: ${currencyFormatter.format(categorySelected.MaxFee.Fee)}`
      : ''
  } ${
    categorySelected.InsertionFee
      ? `Insertion: ${currencyFormatter.format(categorySelected.InsertionFee)}`
      : ''
  }`
}
