/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const parallaxStyle = () => {
  return {
    parallax: {
      height: '90vh',
      maxHeight: '785px',
      overflow: 'auto',
      position: 'relative' as const,
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      margin: '0',
      padding: '0',
      border: '0',
      display: 'flex',
    },
    filter: {
      '&:before': {
        background: 'rgba(0, 0, 0, 0.2`)',
      },
      '&:after,&:before': {
        position: 'absolute' as const,
        zIndex: '1',
        width: '100%',
        height: '100%',
        display: 'block',
        left: '0',
        top: '0',
        content: '""',
      },
    },
    small: {
      height: '380px',
    },
  }
}

export default parallaxStyle
