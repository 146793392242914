import React, { FC } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import styles from 'assets/jss/parallaxStyle'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { FluidObject } from 'gatsby-image'

const useStyles = makeStyles(styles)

interface Props {
  useLightTheme: boolean
}

interface dataQuery {
  lightThemeBackground: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
  darkThemeBackground: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
}

export const PureParallax: FC<Props & dataQuery> = ({
  children,
  useLightTheme,
  lightThemeBackground,
  darkThemeBackground,
}) => {
  const windowScrollTop = 0

  const [transform, setTransform] = React.useState(
    'translate3d(0,' + windowScrollTop + 'px,0)'
  )
  React.useEffect(() => {
    if (window.innerWidth >= 768) {
      window.addEventListener('scroll', resetTransform)
    }
    return function cleanup() {
      if (window.innerWidth >= 768) {
        window.removeEventListener('scroll', resetTransform)
      }
    }
  })
  const resetTransform = () => {
    /* istanbul ignore next */
    const windowScrollTop = window.pageYOffset / 3
    /* istanbul ignore next */
    setTransform('translate3d(0,' + windowScrollTop + 'px,0)')
  }
  const classes = useStyles()
  const parallaxClasses = classNames({
    [classes.parallax]: true,
    [classes.filter]: true,
    [classes.small]: false,
  })

  // Single Image Data
  const imageData = useLightTheme
    ? lightThemeBackground.childImageSharp.fluid
    : darkThemeBackground.childImageSharp.fluid

  const title = `eBay Calculator ${useLightTheme ? 'Light' : 'Dark'} Background`

  return (
    <BackgroundImage
      Tag="section"
      style={{
        transform: transform,
      }}
      className={parallaxClasses}
      fluid={imageData}
      title={title}
      id="fullscreenbg"
      role="img"
      aria-label={title}
      preserveStackingContext={true}
    >
      {children}
    </BackgroundImage>
  )
}

const Parallax: FC<Props> = props => {
  const data: dataQuery = useStaticQuery(
    graphql`
      query {
        lightThemeBackground: file(name: { eq: "bg9" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        darkThemeBackground: file(name: { eq: "landing-bg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `
  )
  return <PureParallax {...props} {...data} />
}

export default Parallax
