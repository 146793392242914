/* istanbul ignore file */
import { KJUR } from 'jsrsasign'

export default function JwtSign(payload: Record<string, unknown>): string {
  const oHeader = {
    alg: process.env.GATSBY_PRIVATE_KEY ? 'RS256' : 'HS512',
    typ: 'JWT',
    kid: 'eBay-key-id',
  }
  const tNow = KJUR.jws.IntDate.get('now')
  const tEnd = tNow + 2 * 60
  const oPayload = {
    ...payload,
    iss: `https://${window.location.hostname}/`,
    nbf: tNow - 10,
    iat: tNow - 10,
    exp: tEnd,
    aud: 'web-app',
  }

  return KJUR.jws.JWS.sign(
    null,
    JSON.stringify(oHeader),
    oPayload,
    process.env.GATSBY_PRIVATE_KEY
      ? Buffer.from(process.env.GATSBY_PRIVATE_KEY, 'base64').toString()
      : 'randomSecret'
  )
}
