import React, { FC, useEffect, useRef, useState } from 'react'
import GridContainer from './creative-tim/Grid/GridContainer.js'
import GridItem from './creative-tim/Grid/GridItem.js'
import { IModel as privateModel } from './sections/PrivateSellerForm'
import styles from 'assets/jss/views/calculations'
import Result from './sections/Result'
import { TabContext, TabPanel, TabList } from '@material-ui/lab'
import * as Sentry from '@sentry/react'
import {
  Tab,
  Theme,
  makeStyles,
  useTheme,
  CircularProgress,
  Switch,
  Grid,
  Badge,
  Chip,
  Box,
  Tooltip,
} from '@material-ui/core'
import { IModel as businessModel } from './sections/BusinessSellerForm'
import classNames from 'classnames'
import Img from 'gatsby-image'
import {
  Categories,
  GTagEventName,
  GTagEventCategory,
  eBayFeeModel,
  eBaySchema,
  EbayFeeBreakdown,
  PaymentFees,
  LastUpdatedApiModel,
  lastUpdatedSchema,
} from './sections/Models'
import useLocalStorage from 'hooks/useLocalStorage'
import Parallax from 'components/parallax/parallax'
import useLogoData from 'hooks/useSiteLogo'
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'
import { useSnackbar } from 'notistack'
import fetch from 'libs/fetchWrapper'
import UpdateIcon from '@material-ui/icons/Update'
import { DateTime } from 'luxon'

const PrivateSellerForm = loadable(
  () =>
    pMinDelay(
      import(/* webpackPrefetch: true */ './sections/PrivateSellerForm'),
      100
    ),
  {
    fallback: <CircularProgress disableShrink />,
  }
)
const BusinessSellerForm = loadable(
  () =>
    pMinDelay(
      import(/* webpackPrefetch: true */ './sections/BusinessSellerForm'),
      100
    ),
  {
    fallback: <CircularProgress disableShrink />,
  }
)

const useStyles = makeStyles((theme: Theme) => styles(theme))

const Calculation: FC = () => {
  const [tabIndex, setTabIndex] = useLocalStorage('selectedTab', 'private')
  const [businessManagedChecked, setBusinessManagedChecked] = useLocalStorage(
    'bManagedChecked',
    true
  )
  const [privateManagedChecked, setPrivateManagedChecked] = useLocalStorage(
    'pManagedChecked',
    false
  )
  const [ebayFees, setEbayFees] = useState<number>()
  const [ebayFeeBreakdown, setEbayFeeBreakdown] = useState<EbayFeeBreakdown[]>()
  const [ebayFeesBeforeCap, setEbayFeesBeforeCap] = useState<number>()
  const [ebayFeesVat, setEbayFeesVat] = useState<number>()
  const [ebayFeesBeforeDiscount, setEbayFeesBeforeDiscount] = useState<number>()
  const [paymentFees, setPaymentFees] = useState<number>()
  const [grossProfit, setGrossProfit] = useState<number>()
  const [netProfit, setNetProfit] = useState<number>()
  const [ebayInsertionFees, setEbayInsertionFees] = useState<number>()
  const [ebayInsertionFeesVat, setEbayInsertionFeesVat] = useState<number>()
  const [ebayFeesCategory, setEbayFeesCategory] = useState<Categories>()
  const [paymentSelected, setPaymentSelected] = useState<PaymentFees>()
  const [vatDue, setVatDue] = useState<number>()
  const [vatReg, setVatReg] = useState<boolean>()
  const [calProcessing, setCalProcessing] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const resultRef = useRef<HTMLInputElement>(null)
  const [lastUpdatedApi, setLastUpdatedApi] = useState<string>()

  function ScrollResultsIntoView() {
    resultRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
  }

  function CalculatePrivate(model: privateModel) {
    setCalProcessing(true)
    const getQueryParams = Object.entries(model)
      .filter(([, val]) => val !== undefined)
      .map(([key, val]) =>
        key === 'category'
          ? [key, model.category?.Name]
          : key === 'payment'
          ? [key, model.payment?.Name]
          : [key, val]
      )
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
      .join('&')
    fetch(`${process.env.GATSBY_API_URL}/calculate/private?${getQueryParams}`)
      .then(res => res.json())
      .then((result: eBayFeeModel) => {
        eBaySchema.parse(result)
        setEbayFees(result.ebayFees)
        setEbayFeeBreakdown(result.ebayFeeBreakdown)
        setEbayFeesBeforeCap(result.ebayFeesBeforeCap)
        setEbayFeesBeforeDiscount(result.ebayFeesBeforeDiscount)
        setEbayInsertionFees(result.ebayInsertionFees)
        setGrossProfit(result.grossProfit)
        setNetProfit(result.netProfit)
        setPaymentFees(result.paymentFees)
        setEbayFeesCategory(model.category)
        setPaymentSelected(model.payment)
        setVatDue(result.vatDue)
        setVatReg(undefined)
        setEbayFeesVat(undefined)
        setEbayInsertionFeesVat(undefined)
        ScrollResultsIntoView()
      })
      .catch(reason => {
        Sentry.captureException(reason)
        NotifyErrorOccurred()
      })
      .finally(() => {
        setCalProcessing(false)
        typeof gtag != 'undefined' &&
          gtag('event', GTagEventName.Private, {
            event_category: GTagEventCategory.Calculations,
            event_label: model.category?.Name,
            category: model.category,
          })
      })
  }

  function CalculateBusiness(model: businessModel) {
    setCalProcessing(true)
    const getQueryParams = Object.entries(model)
      .filter(([, val]) => val !== undefined)
      .map(([key, val]) =>
        key === 'category'
          ? [key, model.category?.Name]
          : key === 'itemVatRate'
          ? [key, model.itemVatRate?.Name]
          : key === 'payment'
          ? [key, model.payment?.Name]
          : [key, val]
      )
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
      .join('&')
    fetch(`${process.env.GATSBY_API_URL}/calculate/business?${getQueryParams}`)
      .then(res => res.json())
      .then((result: eBayFeeModel) => {
        eBaySchema.parse(result)
        setEbayFees(result.ebayFees)
        setEbayFeeBreakdown(result.ebayFeeBreakdown)
        setEbayFeesBeforeCap(result.ebayFeesBeforeCap)
        setEbayFeesBeforeDiscount(result.ebayFeesBeforeDiscount)
        setEbayInsertionFees(result.ebayInsertionFees)
        setGrossProfit(result.grossProfit)
        setNetProfit(result.netProfit)
        setPaymentFees(result.paymentFees)
        setEbayFeesCategory(model.category)
        setPaymentSelected(undefined)
        setVatDue(result.vatDue)
        setVatReg(model.vatRegistered)
        setEbayFeesVat(result.ebayFeesVat)
        setEbayInsertionFeesVat(result.ebayInsertionFeesVat)
        ScrollResultsIntoView()
      })
      .catch(reason => {
        Sentry.captureException(reason)
        NotifyErrorOccurred()
      })
      .finally(() => {
        setCalProcessing(false)
        typeof gtag != 'undefined' &&
          gtag('event', GTagEventName.Business, {
            event_category: GTagEventCategory.Calculations,
            event_label: model.category?.Name,
            category: model.category,
            vatReg: model.vatRegistered,
            vatRate: model.itemVatRate,
          })
      })
  }

  function NotifyErrorOccurred(
    message = 'Retrieving calculations failed, please try again'
  ) {
    enqueueSnackbar(message, {
      variant: 'error',
    })
  }

  useEffect(() => {
    const abortController = new AbortController()
    fetch(`${process.env.GATSBY_API_URL}/last-updated`, {
      signal: abortController.signal,
    })
      .then(res => res.json())
      .then((result: LastUpdatedApiModel) => {
        lastUpdatedSchema.parse(result)
        if (!abortController.signal.aborted) {
          setLastUpdatedApi(result.date)
        }
      })
      .catch(reason => {
        Sentry.captureException(reason)
        NotifyErrorOccurred('Failed to get last updated from server')
      })
    return () => {
      abortController.abort()
    }
  }, [])

  const classes = useStyles()
  const theme = useTheme()
  const { logo } = useLogoData()

  const BusinessTab = (
    <Grid container>
      <Grid item xs>
        <Badge
          data-testid="bManagedBadge"
          badgeContent={'M'}
          invisible={!businessManagedChecked}
          color="primary"
        >
          Business
        </Badge>
      </Grid>
      <Grid
        item
        xs
        style={{ display: tabIndex === 'business' ? undefined : 'none' }}
      >
        <Switch
          data-testid="businessManaged"
          size="small"
          checked={businessManagedChecked}
          onChange={(_event, checked) => setBusinessManagedChecked(checked)}
        />
      </Grid>
    </Grid>
  )

  const PrivateTab = (
    <Grid container>
      <Grid item xs>
        <Badge
          data-testid="pManagedBadge"
          badgeContent={'M'}
          invisible={!privateManagedChecked}
          color="primary"
        >
          Private
        </Badge>
      </Grid>
      <Grid
        item
        xs
        style={{ display: tabIndex === 'private' ? undefined : 'none' }}
      >
        <Switch
          data-testid="privateManaged"
          size="small"
          checked={privateManagedChecked}
          onChange={(_event, checked) => setPrivateManagedChecked(checked)}
        />
      </Grid>
    </Grid>
  )

  return (
    <div data-testid="calculation">
      <Parallax useLightTheme={theme.palette.type === 'light'}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <TabContext value={tabIndex}>
                <TabList
                  data-testid="toggleView"
                  onChange={(_event, newValue) => setTabIndex(newValue)}
                  aria-label="tabs"
                >
                  <Tab
                    data-testid="pToggle"
                    label={PrivateTab}
                    value="private"
                  />
                  <Tab
                    data-testid="bToggle"
                    label={BusinessTab}
                    value="business"
                  />
                </TabList>
                <TabPanel value="private">
                  <Sentry.ErrorBoundary fallback={'An error has occured'}>
                    <PrivateSellerForm
                      onCalculate={model => CalculatePrivate(model)}
                      calProcessing={calProcessing}
                      managed={privateManagedChecked}
                    ></PrivateSellerForm>
                  </Sentry.ErrorBoundary>
                </TabPanel>
                <TabPanel value="business">
                  <Sentry.ErrorBoundary fallback={'An error has occured'}>
                    <BusinessSellerForm
                      onCalculate={model => CalculateBusiness(model)}
                      calProcessing={calProcessing}
                      managed={businessManagedChecked}
                    ></BusinessSellerForm>
                  </Sentry.ErrorBoundary>
                </TabPanel>
              </TabContext>
              <Box
                data-testid="last-updated"
                position="absolute"
                top={theme.spacing(2)}
                right={theme.spacing(2)}
              >
                <Tooltip
                  arrow
                  interactive
                  title={`API last updated on ${
                    lastUpdatedApi &&
                    DateTime.fromISO(lastUpdatedApi).toLocaleString(
                      DateTime.DATETIME_MED
                    )
                  }`}
                  enterTouchDelay={300}
                >
                  <Chip
                    size="small"
                    color="primary"
                    icon={<UpdateIcon />}
                    label={
                      lastUpdatedApi &&
                      DateTime.fromISO(lastUpdatedApi).toRelative()
                    }
                    clickable
                  />
                </Tooltip>
              </Box>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <Sentry.ErrorBoundary fallback={'An error has occured'}>
                <div ref={resultRef}>
                  <Result
                    results={{
                      ebayFees: ebayFees,
                      ebayFeeBreakdown: ebayFeeBreakdown,
                      ebayFeesBeforeCap: ebayFeesBeforeCap,
                      ebayFeesBeforeDiscount: ebayFeesBeforeDiscount,
                      ebayInsertionFees: ebayInsertionFees,
                      ebayCategory: ebayFeesCategory,
                      grossProfit: grossProfit,
                      vatDue: vatDue,
                      netProfit: netProfit,
                      paymentFees: paymentFees,
                      paymentFeesProvider: paymentSelected,
                      vatReg: vatReg,
                      ebayFeesVat: ebayFeesVat,
                      ebayInsertionFeesVat: ebayInsertionFeesVat,
                    }}
                  />
                </div>
              </Sentry.ErrorBoundary>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <div>
            <Img fluid={logo.childImageSharp.fluid} />
          </div>
          <h1>
            Calculate your fees and profit with this simple and free{' '}
            {new Date().getFullYear()} eBay and PayPal fee calculator.
          </h1>
          <h2>Up to date with the latest eBay fee changes</h2>
          <h3>
            Want to sell something on eBay but don’t know if it’s worth the
            hassle? Or maybe you want to set-up your own online business and
            want to see what the profit margin is? Our tool will help you
            workout your eBay fees and transaction fees (eBay or PayPal)
          </h3>
          <h2>Cost of selling on eBay </h2>
          <h3>
            Working out the fees eBay charge can be complicated, especially if
            you factor in different listing types and seller levels if you’re a
            business seller. Don’t get caught out by eBay fees, use our tool and
            market your product for the right price.
          </h3>
        </div>
      </div>
    </div>
  )
}

export default Calculation
